@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:800');

@mixin object($width, $height, $bg) {
	width: $width;
	height: $height;
	background: $bg;
}

@mixin transPos($top, $right, $bottom, $left,$transX,$transY) {
	position: absolute;
	top: $top;
	left: $left;
	right: $right;
	bottom: $bottom;
	transform:translate($transX,$transY);
}

* {
	box-sizing: border-box;
	margin:0;
	padding:0;
}

::-webkit-scrollbar {
	display: none;
}

button{border:none;
	cursor:pointer;}
button:focus {outline:0;}

.card {
	@include object(446px,276px,null);
	display: flex;
	align-items: center;
	.left{
		@include object(240px,276px,#fad811);
		border-radius:23px;
		display:flex;
		align-items:center;
		.wordmark {
			opacity: 0.2;
			transform :rotate(-90deg) scale(0.18);
			margin-left: -750px;
		}
	}
	.right{
		@include object(370px,239px,#0c1f2c);
		position: relative;
    left: -80%;
		transform:translateX(50%);
		border-radius:23px;
		.helmet {
      height: 170px;
			@include transPos(50%,null,null,null,-40%,-50%);
		}
		.productInfo {
			@include object(250px,200px,null);
			margin: 25px 0 0 117.5px;
			color:#fff;
			font-family: 'Open Sans', sans-serif;
			h1 {
				font-weight: 800;
				line-height: 18px;
				font-size: 15px;
				margin-bottom: 40px;
			}
			h2 {
				font-weight: 300;
				font-size: 32px;
				letter-spacing: 1.6px;
				margin-bottom: 0px;
			}
			.details {
        margin-top: 40px;
        font-size: 14px;
				height:145px;
				.size,
				.durability {
					width:50%;
					float:left;
				}
				.size {
					border-right:1px solid #CB1F40;
					padding: 30px 0;
				}
				.durability {
					text-align:center;
					h4 {
						margin-top: 20px;
						margin-bottom: 30px;
					}
				}
			}
			button {
				height:48px;
				background: #CB2140;
				border-radius:90px;
				font-weight: 300;
				line-height: 35px;
				font-size: 14px;
				padding: 0 24px;
				color:#fff;
			}
			i.fav {
				font-size: 12px;
				margin:0 12px 0 24px;
				color:#CB2140;
			}
			a {
				font-size: 12px;
				color:#fff;
				opacity: 0.3;
				text-decoration:none;
			}
			h3 {
				font-weight: 500;
        line-height: 10px;
        letter-spacing: normal;
				font-size: 14px;
			}
			h4 {
				font-weight: bold;
				line-height:normal;
				font-size:12px;
				letter-spacing:3px;
				margin-bottom:15px;
			}
			p {
				@include object(30px,30px,null);
				display:inline-block;
				line-height:normal;
				text-align:center;
				font-weight:300px;
				line-height:28px;
				font-size:14px;
				border-radius:50%;
				transition:0.3s linear;
				border:1px solid transparent;
				cursor:pointer;
				&:hover {
					border:1px solid #CB1F40;
				}
				&:nth-of-type(1) {
					border:1px solid #CB1F40;
				}
			}
		}
	}
}

@media screen and (max-width: 576px) {
  .card {
    @include object(446px,200px,null);
  }
}

.slider {
	@include object(60px,60px,#fff);
	border-radius:50%;
	line-height:70px;
	text-align:center;
	margin-left:-30px;
	box-shadow:0 0 60px rgba(#000,0.2);
	color:#CB1F40;
	cursor:pointer;
	&:nth-of-type(2) {
		@include transPos(50%,-60px,null,null,-50%,-50%);
	}
}

@media screen and (max-width:576px) {
	.card {
		transform: scale(0.7);
		position:static;
	}
}


.youtube {
	font-family: 'Open Sans',sans-serif;
	text-decoration:none;
	@include transPos(null,2.5vw,2vw,null,null,null);
	animation:clickMe 1s ease-in-out infinite;
	@keyframes clickMe {
		50%{
			opacity:0.5;
		}
	}
}